import React from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import HeroServices from '../../components/HeroServices';
import { GatsbyImage } from 'gatsby-plugin-image';
import TwHero from '../../components/TwHero';

const Services = (props) => {
  const heroBackground = props.data.heroBackground;
  const services = props.data.allMdx.edges;
  return (
    <Layout bodyClass="page-services">
      <SEO title="Services" />

      <TwHero
        bg={heroBackground}
        headline="Minnesota Residential Furnace / Air Conditioning (AC) Service, Installation, and Repair"
        text="Family-owned HVAC business operated out of our Burnsville, MN headquarters. We have a small team that will do whatever it takes to earn your business. AC or Furnace troubles? We can help!"
        btnURL="/hvac-service-installation-repair-scheduling"
        btnTxt="Schedule Home HVAC Service"
        featured={[
          {
            name: 'HVAC Housecall',
            color: 'red-800',
            href: '/hvac-service-installation-repair-scheduling',
            description: `HVAC units don't sleep! We offer emergency 24/7 emergency repair, inspection, and installation. If your AC or Furnace is doing anything out of the ordinary, let us know!`,
            icon: '/2022/icons/minnesota-hvac-service-repair-installation.svg',
            btnTxt: 'Get HVAC Support',
          },
          {
            name: 'Furnace Installation & Repairs',
            href: '/hvac-service-installation-repair-scheduling',
            color: 'orange-500',
            description: `Need a new furnace installation? We've got you covered. As a trusted Bryant® HVAC installation team, we've got you covered with the best HVAC units we've found to date. `,
            icon: '/2022/icons/minnesota-furnace-hvac-service-installation-repair.svg',
            btnTxt: 'Schedule Furnace or Heater Service',
          },
          {
            name: 'AC Installation, Service, Repair',
            href: '/hvac-service-installation-repair-scheduling',
            color: 'blue-500',
            description: `Our 3-month window of NEEDING a functioning air conditioning unit is upon us. Coincidentally this is also when they typically decide to die. If you either don't have one or have one on the fritz, get in touch. We can help with AC installations, inspections, and repairs.`,
            icon: '/2022/icons/best-ac-air-conditioning-service-repair-minnesota.svg',
            btnTxt: 'Schedule AC Service or Installation',
          },
        ]}
      />
      <HeroServices
        className="HeroServices"
        title="Commercial & Residential HVAC Services"
        subTitle="We offer quality furnace and heating services for South Metro, Twin Cities homes and businesses."
      />

      <div className="bg-red-800 p-20">
        <h3 class="text-3xl font-extrabold text-white sm:text-4xl">
          More HVAC Information About Our Air Conditioning / Furnace Service,
          Repair, Installation, and Maintenance Plans:
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-20 py-20">
          {services.map((edge, i) => (
            <div key={`${i}-${edge.node.frontmatter.path}`}>
              <div className="card service">
                <div className="card-content">
                  <h2 className="text-4xl font-extrabold text-white">
                    <Link to={edge.node.frontmatter.path}>
                      {edge.node.frontmatter.title}
                    </Link>
                  </h2>
                  <p className="mt-2 text-lg leading-6 font-medium text-red-300">
                    {edge.node.frontmatter.excerpt}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ServicesQuery {
    allMdx(
      filter: { fileAbsolutePath: { regex: "../../mdx/services/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            path
            excerpt
          }
        }
      }
    }
    heroBackground: file(
      relativePath: {
        eq: "best-home-ac-air-conditioning-service-repair-minnesota.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default Services;
